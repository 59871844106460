import React from "react"

// Auth0
import { useAuth } from "auth0/lib/AuthApp"

// jwt
import jwt_decode from "jwt-decode"

// logs
import debugModule from "utils/debug"
const debug = debugModule("token")

// context
const TokenContext = React.createContext()

function useToken() {
  const context = React.useContext(TokenContext)
  if (!context) {
    throw new Error(`useToken must be used within a TokenProvider`)
  }

  const auth = useAuth()

  const [databaseToken, setDatabaseToken] = context.server
  const [serverToken, setServerToken] = context.database
  const [cubeToken, setCubeToken] = context.cube
  const [tokenStatus, setTokenStatus] = context.status
  const [scope, setScope] = context.scope

  const loadAccessTokens = React.useCallback(
    async (ignoreCache = false) => {
      try {
        debug.log(`Load Application JWT Tokens`)
        debug.time("loading-token")
        debug.timeLog("loading-token")
        setTokenStatus("loading")

        const options = {
          leeway: 300,
          ignoreCache,
        }
        const pgrstOptions = {
          audience: process.env.REACT_APP__ORCA_PGRST_API__AUDIENCE,
          ...options,
        }
        console.log(`JWT PGRST: request`, pgrstOptions)
        const databaseAccessTokenPromise = auth
          .getTokenSilently(pgrstOptions)
          .then((token) => {
            if (process.env.REACT_APP_ENV === "development") {
              debug.log(`JWT PGRST: token`, jwt_decode(token))
            } else {
              debug.log(`JWT PGRST loaded!`)
            }
            debug.timeLog("loading-token")
            return token
          })
          .catch((err) => {
            console.error(err)
            throw err
          })

        const serverOptions = {
          audience: process.env.REACT_APP__ORCA_SERVER_API__AUDIENCE,
          ...options,
        }
        console.log(`JWT SERVER: request`, serverOptions)
        const serverAccessTokenPromise = auth
          .getTokenSilently(serverOptions)
          .then((token) => {
            if (process.env.REACT_APP_ENV === "development") {
              debug.log(`JWT SERVER: token`, jwt_decode(token))
            } else {
              debug.log(`JWT SERVER loaded!`)
            }
            debug.timeLog("loading-token")
            return token
          })
          .catch((err) => {
            console.error(err)
            throw err
          })

        const cubeOptions = {
          audience: process.env.REACT_APP__ORCA_CUBE_API__AUDIENCE,
          ...options,
        }
        console.log(`JWT CUBE: request`, cubeOptions)
        const cubeAccessTokenPromise = auth
          .getTokenSilently(cubeOptions)
          .then((token) => {
            if (process.env.REACT_APP_ENV === "development") {
              debug.log(`JWT CUBE: token`, jwt_decode(token))
            } else {
              debug.log(`JWT CUBE loaded!`)
            }
            debug.timeLog("loading-token")
            return token
          })
          .catch((err) => {
            console.error(err)
            throw err
          })

        const [databaseAccessToken, serverAccessToken, cubeAccessToken] =
          await Promise.all([
            databaseAccessTokenPromise,
            serverAccessTokenPromise,
            cubeAccessTokenPromise,
          ])

        var auth0ServerTokenClaims = jwt_decode(serverAccessToken)

        const scopeList =
          auth0ServerTokenClaims[
            `${process.env.REACT_APP__ORCA_SERVER_API__AUDIENCE}:scope`
          ]

        if (scopeList) {
          debug.log(`[scopes]`)
          scopeList.forEach((scope) => {
            debug.log(`[scopes] ${scope}`)
          })
        }

        debug.timeLog("loading-token")
        debug.timeEnd("loading-token")

        setScope(scopeList)
        setDatabaseToken(databaseAccessToken)
        setServerToken(serverAccessToken)
        setCubeToken(cubeAccessToken)
        setTokenStatus("success")
      } catch (e) {
        debug.error(e)
      }
    },
    [
      auth,
      setDatabaseToken,
      setScope,
      setServerToken,
      setCubeToken,
      setTokenStatus,
    ]
  )

  return {
    tokenStatus,
    databaseToken,
    serverToken,
    cubeToken,
    loadAccessTokens,
    SCOPE: scope,
    HAS_ACCESS_TO_SCOPE: React.useCallback(
      (actions) => actions.every((act) => scope.includes(act)),
      [scope]
    ),
  }
}

function TokenProvider(props) {
  const [cubeToken, setCubeToken] = React.useState(null)
  const [databaseToken, setDatabaseToken] = React.useState(null)
  const [serverToken, setServerToken] = React.useState(null)
  const [scopeValue, setScope] = React.useState([])
  const [tokenStatus, setTokenStatus] = React.useState("idle")

  const scope = React.useMemo(() => [scopeValue, setScope], [scopeValue])
  const status = React.useMemo(
    () => [tokenStatus, setTokenStatus],
    [tokenStatus]
  )
  const server = React.useMemo(
    () => [databaseToken, setDatabaseToken],
    [databaseToken]
  )
  const database = React.useMemo(
    () => [serverToken, setServerToken],
    [serverToken]
  )
  const cube = React.useMemo(() => [cubeToken, setCubeToken], [cubeToken])
  return (
    <TokenContext.Provider
      value={{
        scope,
        status,
        server,
        database,
        cube,
      }}
      {...props}
    />
  )
}

export { TokenProvider, useToken }
