import createAuth0Client from "@auth0/auth0-spa-js"
import axios from "axios"
import merge from "deepmerge"

export default async function initAuth0Client(options, callbacks) {
  const {
    onAuthProviderClientInitialized,
    onAuthenticationStateChange,
    onLoadingStateChange,
    onUserStateChange,
    onIdTokenClaimsChange,
    onRedirect,
  } = callbacks

  const optionsWithCache = {
    ...options,
    cacheLocation: ["development", "test"].includes(process.env.REACT_APP_ENV)
      ? "localstorage"
      : "memory",
  }

  const auth0Client = await createAuth0Client(optionsWithCache)
  const authProviderClient = {
    isAuthenticated: () => auth0Client.isAuthenticated(),
    login: (...args) =>
      auth0Client.loginWithRedirect(
        merge.all([
          { appState: { targetUrl: "/" } },
          args[0] ?? {},
          { screen_hint: "login", allow_log_in: true },
        ]),
        ...args.slice(1)
      ),
    signup: (...args) =>
      auth0Client.loginWithRedirect(
        merge.all([
          { appState: { targetUrl: "/" } },
          args[0] ?? {},
          { screen_hint: "signup", allow_sign_up: true },
        ]),
        ...args.slice(1)
      ),
    logout: (...args) => auth0Client.logout(...args),
    getUser: (...args) => auth0Client.getUser(...args),
    getIdTokenClaims: (...args) => auth0Client.getIdTokenClaims(...args),
    getTokenSilently: (...args) => auth0Client.getTokenSilently(...args),
    switchClinic: async ({ clinic_id, webuser_id }) => {
      try {
        const accessToken = await auth0Client.getTokenSilently({
          audience: process.env.REACT_APP__ORCA_PGRST_API__AUDIENCE,
        })
        const { data } = await axios.post(
          `${process.env.REACT_APP__ORCA_PGRST_API__HOST}/rpc/webuser__switch_current_clinic`,
          { clinic_id, webuser_id },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )

        if (!data) {
          throw new Error("Failed to switch clinic")
        }
      } catch (err) {
        console.error(err)
        throw err
      }
    },
    createClinic: async ({ data }) => {
      try {
        const accessToken = await auth0Client.getTokenSilently({
          audience: process.env.REACT_APP__ORCA_PGRST_API__AUDIENCE,
        })
        return axios.post(
          `${process.env.REACT_APP__ORCA_PGRST_API__HOST}/rpc/clinic__insert`,
          data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
      } catch (err) {
        console.error(err)
        throw err
      }
    },
  }

  if (process.env.REACT_APP_ENV === "development") {
    window.auth0 = {
      client: auth0Client,
      authProviderClient: authProviderClient,
      options: optionsWithCache,
    }
    console.log("[auth0] assigned auth0 data on window.auth0")
  }
  onAuthProviderClientInitialized(authProviderClient)

  try {
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      const { appState } = await auth0Client.handleRedirectCallback()

      onRedirect(appState)
    }

    // code for pre-loading the user's information if we have their token in
    // localStorage
    const isAuthenticated = await auth0Client.isAuthenticated()
    onAuthenticationStateChange(isAuthenticated)
    if (isAuthenticated) {
      const user = await auth0Client.getUser()
      onUserStateChange(user)
      const idTokenClaims = await auth0Client.getIdTokenClaims()
      onIdTokenClaimsChange(idTokenClaims)
    }
  } catch (err) {
    console.error(err)
  } finally {
    onLoadingStateChange(false)
  }
}
